import React from "react";
import "./App.css";
import { ProjectLink, ProjectLinkProps } from "./project-link/ProjectLink";

const projects: ProjectLinkProps[] = [
  {
    link: "https://chrome.google.com/webstore/detail/beehive/inghggfaolaofdeienkonigkcmomeadc",
    name: "Beehive",
    repoName: "beehive",
    description:
      "I wanted a way to share my score with other people who played the New York Times spelling bee. I " +
      "also wanted to be able to talk about which words each of us had found without spoilers, so I made this " +
      "extension for Chrome. If you want to add me as a friend, email me your friend code!",
  },
  {
    link: "https://adventure-of-sfinx.web.app/",
    name: "Adventure of Sfinx",
    repoName: "adventure-of-sfinx",
    description:
      "This is an adventure game I made for my friend. Once you login with Google, you can get past the" +
      " first screen with the credentials SFINX/RUTTLE. You won't be able to play the entire thing, as there are" +
      " some physical components required, but you can poke around.",
  },
  {
    link: "https://github.com/elisiskind/BobSpace",
    name: "BobSpace",
    description:
      "I made a (very) basic tutorial for people to learn some basic React concepts like useEffect and " +
      "useState. The final product is a site that let's you look at pictures of my cat, Bob.",
  },
];

function App() {
  return (
    <div className="App">
      <div className="heading">
        <h1>Hi, I'm Eli.</h1>
        <h2>This is my website.</h2>
      </div>

      <div className={"content"}>
        <h3>Here are some of my projects.</h3>
        {projects.map((project) => {
          return <ProjectLink {...project} />;
        })}
      </div>
    </div>
  );
}

export default App;
