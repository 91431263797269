import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {useEffect, useState} from "react";

const categories: Record<string, [string, string, string, string]> = {
  'Levin / Rifkins': ["Emily", "Jane", "Julia", "Bob"],
  "_ Boat": ["Duck", "U", "Motor", "Gravy"],
  "Granola Bars": ["Luna", "Clif", "Kind", "Chewy"],
  "Things You Say on a Walkie Talkie": [
    "Roger",
    "Copy",
    "Over",
    "Loud and Clear",
  ],
};

const randomizedWords = () => {
  return Object.values(categories)
    .flatMap((category) => category)
    .map((word) => ({ word, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ word }) => word);
};

export const Categories = () => {
  const [words, setWords] = useState(randomizedWords());
  const [selected, setSelected] = useState<string[]>([]);
  const [completed, setCompleted] = useState<string[]>([]);
  const [attempts, setAttempts] = useState(0);

  const toggleWord = (word: string) => {
    if (selected.includes(word)) {
      setSelected(selected.filter((selectedWord) => selectedWord !== word));
    } else {
      setSelected([...selected, word]);
    }
  };

  const submit = () => {
    const category = Object.entries(categories).find(([category, words]) => {
      return words.every((word) => selected.includes(word));
    });
    if (category) {
      setCompleted([...completed, category[0]]);
      localStorage.setItem('completed', JSON.stringify([...completed, category[0]]))
    } else {
      setAttempts(attempts + 1);
    }
    setSelected([]);
  };

  const ready = selected.length >= 4;

  useEffect(() => {
    const storedState = localStorage.getItem('completed');
    if (storedState) {
      setCompleted(JSON.parse(storedState))
    }
  }, [])

  return (
    <Box width={"100%"} padding={2}>
      <Box
        padding={4}
        sx={{ background: "white" }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={2}
      >
        <Box>
          <Typography variant={"h3"} color={"primary"}>
            Connections!
          </Typography>
          <Typography color={"primary"}>
            Incorrect guesses: {attempts}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {completed.map((category) => {
            return (
              <>
                <Grid xs={12}>{category}</Grid>
                {categories[category].map((word) => {
                  return (
                    <Grid xs={3} key={word}>
                      <Button
                        variant={"contained"}
                        sx={{ padding: 2 }}
                        fullWidth
                        disabled
                      >
                        {word}
                      </Button>
                    </Grid>
                  );
                })}
              </>
            );
          })}
          {words
            .filter(
              (word) =>
                !completed
                  .flatMap((completedCatogory) => categories[completedCatogory])
                  .includes(word),
            )
            .map((word) => {
              const isSelected = selected.includes(word);
              return (
                <Grid xs={3} key={word}>
                  <Button
                    color={isSelected ? "primary" : "secondary"}
                    variant={"contained"}
                    sx={{ padding: 2 }}
                    fullWidth
                    disabled={ready && !isSelected}
                    onClick={() => toggleWord(word)}
                  >
                    {word}
                  </Button>
                </Grid>
              );
            })}
        </Grid>
        <Box>
          <Button onClick={() => setWords(randomizedWords())}>Shuffle</Button>
          <Button onClick={submit}>Submit</Button>
          <Button onClick={() => setSelected([])}>Clear</Button>
        </Box>
      </Box>
    </Box>
  );
};
